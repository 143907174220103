import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import 'tailwindcss/tailwind.css';

const ContadorCatan = () => {
  const [counters, setCounters] = useState(() => {
    const storedCounters = localStorage.getItem('counters');
    return storedCounters ? JSON.parse(storedCounters) : Array.from({ length: 11 }, () => 0);
  });

  const [totalPressCount, setTotalPressCount] = useState(
    () => parseInt(localStorage.getItem('totalPressCount')) || 0
  );

  const [maxCountNumber, setMaxCountNumber] = useState(0);

  const lastUpdateTime = useRef(localStorage.getItem('lastUpdateTime'));

  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      const chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: Array.from({ length: 11 }, (_, i) => `${i + 2}`),
          datasets: [{
            label: 'Contador Catan',
            data: counters,
            backgroundColor: 'rgba(255, 228, 196, 0.8)', // Wheat color
            borderColor: 'rgba(210, 180, 140, 1)', // Tan color
            borderWidth: 1,
          }],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              precision: 0, // Forzar a valores enteros
            },
          },
        },
      });

      return () => {
        chart.destroy();
      };
    }
  }, [counters]);

  useEffect(() => {
    const currentMaxCount = Math.max(...counters);
    setMaxCountNumber(counters.indexOf(currentMaxCount) + 2);
  }, [counters]);

  const handleButtonClick = (index) => {
    const newCounters = [...counters];
    newCounters[index]++;
    setCounters(newCounters);
    setTotalPressCount((prevCount) => prevCount + 1);
    updateLocalStorage(newCounters, totalPressCount + 1);
  };

  const handleResetClick = () => {
    const isConfirmed = window.confirm("¿Estás seguro de que quieres reiniciar?");
    
    if (isConfirmed) {
      setCounters(Array.from({ length: 11 }, () => 0));
      setTotalPressCount(0);
      updateLocalStorage(Array.from({ length: 11 }, () => 0), 0);
    }
  };

  const updateLocalStorage = (newCounters, newTotalPressCount) => {
    localStorage.setItem('counters', JSON.stringify(newCounters));
    localStorage.setItem('totalPressCount', newTotalPressCount);
    localStorage.setItem('lastUpdateTime', new Date().toISOString());
  };

  useEffect(() => {
    const currentTime = new Date().getTime();
    const lastUpdate = lastUpdateTime.current
      ? new Date(lastUpdateTime.current).getTime()
      : 0;

    const elapsedTime = currentTime - lastUpdate;
    const limitTime = 5 * 60 * 60 * 1000; // 5 hours in milliseconds

    if (elapsedTime > limitTime) {
      // Reset data if more than 5 hours have passed
      handleResetClick();
    }
  }, [lastUpdateTime]);

  const buttonElements = counters.map((value, index) => (
    <button
      key={index}
      onClick={() => handleButtonClick(index)}
      className="bg-orange-400 hover:bg-orange-500 text-white font-bold p-2 rounded"
    >
      {index + 2}
    </button>
  ));

  return (
    <div className="grid grid-cols-3 gap-4 items-center px-4">
  
      <canvas id="chart" width="200" height="200" ref={chartRef} className="col-span-3"></canvas>
      {buttonElements}
      <p className="col-span-3 text-center">Total: {totalPressCount}</p>
      <p className="col-span-3 text-center">
        Máximo Repetido: {maxCountNumber} (Veces: {counters[maxCountNumber - 2]})
      </p>
      <button
        onClick={handleResetClick}
        className="bg-red-500 hover:bg-red-700 text-white font-bold p-2 rounded col-span-3"
      >
        Reset
      </button>
    </div>
  );
};

export default ContadorCatan;
